<template>
  <div id="authEndTableDialog" ref="authEndTableDialog" >
      <a-modal v-model="visible"
              :title="ctitle" 
              :width="960"
			        @ok="handleOk"
              :getContainer="() => this.$refs.authEndTableDialog" 
      >
          <div style="height:100%;width:100%;" v-loading="loading" :element-loading-text="$t('alarm.a0')">
              <div class="dsp">
                <div v-if="basic.action==2">
                  <!-- placeholder="认证终端名称" title="认证终端名称" -->
                   <a-input v-model="equipmentName" :placeholder="$t('eqct.a36')" title="$t('eqct.a36')" />
                </div>
                <div v-else>
                  <!--  placeholder="设备名称" title="设备名称" -->
                    <a-input v-model="equipmentName" :placeholder="$t('eqct.a16')" :title="$t('eqct.a16')" />
                </div>
                <a-button type="primary" @click="getEndEquipmentList" >{{$t('energy.search')}}</a-button> 
              </div>
              <div class="main" ref="main" v-table-size="tableSize">
              <a-table
                :row-selection="rowSelection"
                :columns="columns"
                :data-source="data"
                :row-key="record => record.id" 
                :pagination="pagination"
                size="small"
                :scroll="size"
              />
              </div>
          </div>
      </a-modal>
  </div>
</template>
<script>


import { getBasicEquipmentList } from "../../../api/door";
export default {
  name: 'EndTable',
  props: {
    title: {
      type: String,
      default: "设备选择",
    },
    basic: {
      type: Object,
      default: {},
    }
  }, 
  data() {
    return {
      loading:false,
      visible:false,
      checkType:'checkbox',
      data:[],
      columns:[],
      columns2:[
        {
          id:"1",
          title: "NO.",
          dataIndex:"NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          align: "center",
          width: 60,
        },
        {
          title: this.$t('eqct.a22'),//'认证终端名称',//
          dataIndex: 'credentialName',
          width: 200,
        },
        {
          title: this.$t('eqct.a33'),//'设备类型',//this.$t('energy.enedata164'),
          dataIndex: 'memberTypeLabel',
           width: 120,
        },
        {
          title: this.$t('eqct.a63'),//'出入类型',//this.$t('energy.enedata164'),
          dataIndex: 'inOutType',
          width: 100,
        },
        {
          title: this.$t('energy.enedata254'),//'建筑名称',//this.$t('energy.enedata254'),
          dataIndex: 'builName',
          width: 150,
        },
        {
          title: this.$t('eqct.a64'),//'站点名称',//this.$t('energy.enedata164'),
          dataIndex: 'siteName',
           width: 150,
        },
        
      ],
      columns1:[
        {
          id:"1",
          title: "NO.",
          dataIndex:"order",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          align: "center",
          width: 60,
        },
        {
          title: this.$t('eqct.a22'),//'设备名称',//this.$t('energy.enedata164'),
          dataIndex: 'doorName',
          width: 150,
        },
        {
          title: this.$t('eqct.a33'),//'设备类型',//this.$t('energy.enedata164'),
          dataIndex: 'memberTypeLabel',
           width: 120,
        },
        {
          title:  this.$t('energy.enedata254'),//'建筑名称',//this.$t('energy.enedata164'),
          dataIndex: 'builName',
          width: 150,
        },
        {
          title: this.$t('eqct.a64'),//'站点名称',//this.$t('energy.enedata164'),
          dataIndex: 'siteName',
           width: 150,
        },
        
      ],
      selectedRowKeys:[],
      selectedRows:[],
      pagination: {
        total: 0, //数据总数
        pageSize: 100, //每页中显示10条数据
        showTotal: (total) => `${total} ${this.$t("attendance.a11")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      ctitle:'',
      equipmentName:'',
      size:{y:600},
    };
  },
  watch:{
    "$parent.endTableDialogVisible":{
      handler(val){
           console.log("visible",val);
          if(val){              
            this.ctitle =  this.$parent.chooicEndTitle;
            if(this.basic.action===1){
              this.columns = this.columns1;
            }else if(this.basic.action===2){
              this.columns = this.columns2;
            }
            this.visible = true;
            this.getEndEquipmentList();
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
        handler(val){
            console.log("visible",val);
            if(!val){
              this.$parent.endTableDialogVisible =  val;
              this.selectedRowKeys = [];
              this.equipmentName = '';
              this.ctitle = '';
              
            }
        },
        deep:true,
        immediate:true,
    },
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        type:this.checkType,
      };
    },
  },
  methods: {
    handleOk(){
      console.log('handleOk>>',this.selectedRows);
      if( this.selectedRows && this.selectedRows.length>0){
        let arr = [];
        this.selectedRows.forEach(row => {
           if(this.conDeviceType==='1'){
                row.fcncls = "";
                row.fcnvaild = false;
                row.fcnmsg = '';
                row.fdkcls = "";
                row.fdkvaild = false;
                row.fdkmsg = '';
                row.fdpcls = "";
                row.fdpvaild = false;
                row.fdpnmsg = '';
                row.fdbcls = "";
                row.fdbvaild = false;
                row.fdbmsg = '';
            } else {
                row.eqncls = "";
                row.eqnvaild = false;
                row.eqnmsg = '';
                
            }
          arr.push(row);
        });

        this.$parent.equipmentList = [...this.$parent.equipmentList,...arr];
        this.visible = false;
      }
        
    },
    onSelectChange(selectedRowKeys,selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    getEndEquipmentList(){
      let ids = [];
      if(this.basic.ends && this.basic.ends.length>0){
        this.basic.ends.forEach(e => {
          ids.push(e.id);
        });
      }
      let param = {
          action: this.basic.action,
          clientId: this.basic.clientId,
          sitegrId: this.basic.sitegrId,
          siteId: this.basic.siteId,
          tenantId: 0,
          equipmentName: this.equipmentName,
          ids:ids,  
      }
      this.loading = true;
      this.data = [];
      console.log("Get Basic Equipment param>>>",param);
      getBasicEquipmentList(param)
      .then((res) => {
        console.log("Get Basic Equipment List>>>",res);
        let { data } = res;
        this.data = data;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },
    tableSize() {
      const indexDom = document.getElementById("authEndTableDialog");
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main")[0].clientHeight;
        const title  = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
        const tableHeight = height - 50 - title;
        this.size.y = tableHeight;
      }
    },
  },
};
</script>
<style scoped>
#authEndTableDialog{
  height: 700px;
  width:100%;
  position: relative;  
}
 /* div /deep/.ant-modal{
     top:10px;
 } */
 div /deep/.ant-modal-body .el-loading-mask {
    position: absolute;
    z-index: 2000;
    background-color: rgba(255,255,255,.9);
    margin: 0;
    top: -60px;
    right: -10px;
    bottom: -60px;
    left: -10px;
    transition: opacity .3s;
}
div /deep/.ant-modal-body{padding: 5px 10px;height: 640px;}
.dsp{
  padding: 5px;
  display: flex;
  justify-content: space-between;  
  
}
.main {
  height: calc(100% - 50px);
  padding: 5px;
  border: 1px solid #d5d5d5;
}
</style>
